<template>

  <b-card-code
    no-body
    title="Shipments"
  >

    <b-overlay
      :show="loading"
      rounded="sm"
    >

      <b-table
        striped
        hover
        :items="shipments"
        :fields="fields"
      >

        <template #cell(id)="data">
          <span v-b-tooltip.hover.top="data.item.id">
            #
          </span>
        </template>

        <template #cell(status)="data">
          <select
            class="form-control"
            @change="onStatusChange($event, data.item.id)"
          >
            <option
              value="Intransit"
              :selected="data.item.status == 'Intransit'"
            >
              In transit
            </option>
            <option
              value="Production"
              :selected="data.item.status == 'Production'"
            >
              Production
            </option>
          </select>
        </template>

        <template #cell(createdAt)="data">
          {{ data.item.createdAt|formatDateTime }}
        </template>

        <template #cell(actions)="data">
          <router-link :to="{ name: 'edit-shipment', params: { shipmentId: data.item.id }}">
            <b-button

              variant="primary"
              size="sm"
              class="btn-tour-finish mr-1"
            >
              Edit
            </b-button>

          </router-link>

          <a
            class="text-danger"
            @click="remove(data.item.id)"
          >Remove</a>
        </template>

      </b-table>

      <div
        v-if="rows > 25"
        class="mt-1 d-flex justify-content-center"
      >
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
          @change="handlePageChange"
        />
      </div>

    </b-overlay>

  </b-card-code>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BButton, BPagination, VBTooltip, BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BPagination,
    BOverlay,
    ToastificationContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      shipments: [],
      perPage: 25,
      fields: ['id', 'sku', 'channel', 'value', 'status', 'createdAt', 'actions'],
      current: 1,
      rows: 0,
    }
  },

  mounted() {
    this.loadShipments()
  },

  methods: {

    handlePageChange(value) {
      this.page = value
      this.loadShipments(this.page)
    },

    loadShipments(page = 1) {
      this.loading = true
      this.$store.dispatch('shipment/fetchAll', page)
        .then(result => {
          this.shipments = result.data.docs
          this.currentPage = result.data.page
          this.rows = result.data.totalDocs
          this.loading = false
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading items', 'danger')
        }).finally(() => {
          this.loading = false
        })
    },

    remove(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger ',
          cancelButton: 'btn btn-outline-primary  ml-1',
        },
        buttonsStyling: false,

      }).then(result => {
        if (result.value === true) {
          this.$store.dispatch('shipment/remove', id).then(
            () => {
              this.showToast('Success', 'Removed with success', 'success')
              this.loadShipments()
            },
            error => {
              this.showToast('Error', error.response?.data?.message || error?.message || 'Error while removing item', 'danger')
            },
          )
        }
      },
      error => {
        console.log(err)
      })
    },
    onStatusChange($event, id) {
      const data = {
        id,
        status: event.target.value,
      }

      this.$store.dispatch('shipment/update', data)
        .then(result => {
          this.showToast('Success', 'Item was updated successfully', 'success')
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while updating item', 'danger')
        })
    },

    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },

  },
}
</script>
